﻿import { room } from "./room";

export const Room_Information: room = {
    'rooms': [
        {
            "passengers": 1,
            "room_types": [
                {
                    "name": "1 Single Room",
                    "room_type_combinations": [
                        {
                            "occupants": 1,
                            "name": "Single Room",
                            "passenger_links": []
                        }
                    ]
                },
                {
                    "name": "1 Dorm Bed",
                    "room_type_combinations": [
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        }
                    ]
                }
            ]
        },
        {
            "passengers": 2,
            "room_types": [
                {
                    "name": "1 Twin Room",
                    "room_type_combinations": [
                        {
                            "occupants": 2,
                            "name": "Twin Room",
                            "passenger_links": []
                        }
                    ]
                },
                {
                    "name": "1 Double Room",
                    "room_type_combinations": [
                        {
                            "occupants": 2,
                            "name": "Double Room",
                            "passenger_links": []
                        }
                    ]
                },
                {
                    "name": "2 Single Rooms",
                    "room_type_combinations": [
                        {                         
                            "occupants": 1,
                            "name": "Single Room",
                            "passenger_links": []
                        }, 
                        {
                            "occupants": 1,
                            "name": "Single Room",
                            "passenger_links": []
                        }
                    ]
                },
                {
                    "name": "2 Dorm Beds",
                    "room_type_combinations": [
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        }
                    ]
                }
            ]
        },
        {
            "passengers": 3,
            "room_types": [
                {
                    "name": "1 Family 3 Room",
                    "room_type_combinations": [
                        {
                            "occupants": 3,
                            "name": "Family 3",
                            "passenger_links": []
                        }
                    ]
                },
                {
                    "name": "1 Twin Room, 1 Single Room",
                    "room_type_combinations": [
                        {
                            "occupants": 2,
                            "name": "Twin Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Single Room",
                            "passenger_links": []
                        }
                    ]
                },
                {
                    "name": "1 Double Room, 1 Single Room",
                    "room_type_combinations": [
                        {
                            "occupants": 2,
                            "name": "Double Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Single Room",
                            "passenger_links": []
                        }
                    ]
                },
                {
                    "name": "3 Dorm Beds",
                    "room_type_combinations": [
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        }
                    ]
                }
            ]
        },
        {
            "passengers": 4,
            "room_types": [
                {
                    "name": "1 Family 4 Room",
                    "room_type_combinations": [
                        {
                            "occupants": 4,
                            "name": "Family 4",
                            "passenger_links": []
                        }
                    ]
                },
                {
                    "name": "1 Family 3 Room, 1 Single Room",
                    "room_type_combinations": [
                        {
                            "occupants": 3,
                            "name": "family 3 Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Single Room",
                            "passenger_links": []
                        }
                    ]
                },
                {
                    "name": "2 Twin Rooms",
                    "room_type_combinations": [
                        {
                            "occupants": 2,
                            "name": "Twin Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 2,
                            "name": "Twin Room",
                            "passenger_links": []
                        }
                    ]
                },
                {
                    "name": "2 Double Rooms",
                    "room_type_combinations": [
                        {
                            "occupants": 2,
                            "name": "Double Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 2,
                            "name": "Double Room",
                            "passenger_links": []
                        }
                    ]
                },
                {
                    "name": "1 Double Room, 1 Twin Room",
                    "room_type_combinations": [
                        {
                            "occupants": 2,
                            "name": "Double Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 2,
                            "name": "Twin Room",
                            "passenger_links": []
                        }
                    ]
                },
                {
                    "name": "4 Dorm Beds",
                    "room_type_combinations": [
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        }
                    ]
                }
            ]
        },
        {
            "passengers": 5,
            "room_types": [
                {
                    "name": "1 Family 4 Room, 1 Single Room",
                    "room_type_combinations": [
                        {
                            "occupants": 4,
                            "name": "Family 4",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Single Room",
                            "passenger_links": []
                        }
                    ]
                },
                {
                    "name": "1 Family 3 Room, 1 Twin Room",
                    "room_type_combinations": [
                        {
                            "occupants": 3,
                            "name": "Family 3",
                            "passenger_links": []
                        },
                        {
                            "occupants": 2,
                            "name": "Twin Room",
                            "passenger_links": []
                        }
                    ]
                },
                {
                    "name": "1 Family 3 Room, 1 Double Room",
                    "room_type_combinations": [
                        {
                            "occupants": 3,
                            "name": "Family 3",
                            "passenger_links": []
                        },
                        {
                            "occupants": 2,
                            "name": "Double Room",
                            "passenger_links": []
                        }
                    ]
                },
                {
                    "name": "2 Twin Room, 1 Single",
                    "room_type_combinations": [
                        {
                            "occupants": 2,
                            "name": "Twin Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 2,
                            "name": "Twin Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Single Room",
                            "passenger_links": []
                        }
                    ]
                },
                {
                    "name": "2 Double Room, 1 Single",
                    "room_type_combinations": [
                        {
                            "occupants": 2,
                            "name": "Double Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 2,
                            "name": "Double Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Single Room",
                            "passenger_links": []
                        }
                    ]
                },
                {
                    "name": "5 Dorm Beds",
                    "room_type_combinations": [
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        }
                    ]
                }
            ]
        },
        {
            "passengers": 6,
            "room_types": [
                {
                    "name": "Dorm bed x4 + Twin Private Room x1",
                    "room_type_combinations": [
                        {
                            "occupants": 2,
                            "name": "Private Twin Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                    ]
                },
                {
                    "name": "Dorm bed x3 + Triple Private Room x1",
                    "room_type_combinations": [
                        {
                            "occupants": 3,
                            "name": "Private Triple Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                    ]
                },
                {
                    "name": "Dorm bed x3 + Quad Private Room x1",
                    "room_type_combinations": [
                        {
                            "occupants": 4,
                            "name": "Private Quad Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                    ]
                },
                {
                    "name": "Twin Private Room x1 + Quad Private Room x1",
                    "room_type_combinations": [
                        {
                            "occupants": 4,
                            "name": "Private Quad Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 2,
                            "name": "Private Twin Room",
                            "passenger_links": []
                        },
                    ]
                },
                {
                    "name": "1 Family 4 Room, 2 Single Room",
                    "room_type_combinations": [
                        {
                            "occupants": 4,
                            "name": "Family 4",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Single Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Single Room",
                            "passenger_links": []
                        },
                    ]
                },
                {
                    "name": "1 Family 3 Room, 1 Twin Room, 1 Single Room",
                    "room_type_combinations": [
                        {
                            "occupants": 3,
                            "name": "Family 3",
                            "passenger_links": []
                        },
                        {
                            "occupants": 2,
                            "name": "Twin Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Single Room",
                            "passenger_links": []
                        },
                    ]
                },
                {
                    "name": "1 Family 3 Room, 1 Double Room, 1 Single Room",
                    "room_type_combinations": [
                        {
                            "occupants": 3,
                            "name": "Family 3",
                            "passenger_links": []
                        },
                        {
                            "occupants": 2,
                            "name": "Double Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Single Room",
                            "passenger_links": []
                        },
                    ]
                },
                {
                    "name": "2 Twin Room, 2 Single Room",
                    "room_type_combinations": [
                        {
                            "occupants": 2,
                            "name": "Twin Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 2,
                            "name": "Twin Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Single Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Single Room",
                            "passenger_links": []
                        }
                    ]
                },
                {
                    "name": "2 Double Room, 2 Single Room",
                    "room_type_combinations": [
                        {
                            "occupants": 2,
                            "name": "Double Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 2,
                            "name": "Double Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Single Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Single Room",
                            "passenger_links": []
                        }
                    ]
                },
                {
                    "name": "2 Twin Room, 1 Double Room",
                    "room_type_combinations": [
                        {
                            "occupants": 2,
                            "name": "Twin Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 2,
                            "name": "Twin Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 2,
                            "name": "Double Room",
                            "passenger_links": []
                        }
                    ]
                },
                {
                    "name": "2 Double Room, 1 Twin Room",
                    "room_type_combinations": [
                        {
                            "occupants": 2,
                            "name": "Double Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 2,
                            "name": "Double Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 2,
                            "name": "Twin Room",
                            "passenger_links": []
                        }
                    ]
                },
                {
                    "name": "3 Twin Room",
                    "room_type_combinations": [
                        {
                            "occupants": 2,
                            "name": "Twin Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 2,
                            "name": "Twin Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 2,
                            "name": "Twin Room",
                            "passenger_links": []
                        }
                    ]
                },
                {
                    "name": "3 Double Room",
                    "room_type_combinations": [
                        {
                            "occupants": 2,
                            "name": "Double Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 2,
                            "name": "Double Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 2,
                            "name": "Double Room",
                            "passenger_links": []
                        }
                    ]
                },
                {
                    "name": "6 Dorm Beds",
                    "room_type_combinations": [
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        }
                  ]
                }
              ]
            },
            {
                "passengers": 7,
                "room_types": [
                    {
                    "name": "7 Dorm Beds",
                    "room_type_combinations": [
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        }
                  ]
                },
            ]
        },
        {
            "passengers": 8,
            "room_types": [
                {
                    "name": "8 Dorm Beds",
                    "room_type_combinations": [
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        }
                  ]
                },
            ]
        },
        {
            "passengers": 9,
            "room_types": [
            {
                    "name": "9 Dorm Beds",
                    "room_type_combinations": [
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        }
                  ]
                },
            ]
        },
        {
            "passengers": 10,
            "room_types": [
            {
                    "name": "10 Dorm Beds",
                    "room_type_combinations": [
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Dorm Bed",
                            "passenger_links": []
                        }
                  ]
                },
            ]
        },

    ]
};